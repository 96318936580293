import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../components/layout'
// import SEO from "../components/seo"

const NotFoundPage = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      {/*<SEO title="404: Not Found" />*/}
      <h1>Not Found</h1>
      <p>
        <img
          src="https://media.giphy.com/media/6uGhT1O4sxpi8/giphy.gif"
          alt="Not found"
        />
      </p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
