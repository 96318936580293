import React from 'react'
import {Link} from 'gatsby'

import '../styles/main.css'

const Layout = ({location, title, children}) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header
  const className =
    location.pathname === rootPath ||
    location.pathname === `${rootPath}/projects`
      ? 'container'
      : 'container container-blog'

  if (location.pathname === rootPath) {
    header = <React.Fragment>{title}</React.Fragment>
  } else {
    header = (
      <Link to="/" className="font-extrabold">
        {title}
      </Link>
    )
  }

  return (
    <div className={className}>
      <header>
        <h1 className="text-lg font-extrabold">{header}</h1>
      </header>
      {children}
    </div>
  )
}

export default Layout
